import SecureLS from 'secure-ls'
import axios from 'axios'
import { defineStore } from 'pinia'
import { ref } from 'vue'
import { notification } from 'ant-design-vue'

export const settingsStore = defineStore('settings', () => {
  const settings = ref<{ id: number, setting_name: string, setting_value: never }[]>([])
  const loading = ref(false)
  const errors = ref<{[key: string]: string[]}>({})
  const ls = new SecureLS({ isCompression: false })

  function loadSettings () : void {
    loading.value = true
    axios.get(process.env.VUE_APP_API_URL + '/users/constructionSiteApp/settings').then((r) => {
      if (r.data.length) {
        r.data.forEach((setting:{ id:number, setting_name:string, setting_value:never }) => {
          settings.value.push(setting)
          new SecureLS({ isCompression: false }).set(setting.setting_name, setting.setting_value)
        })
      }
    }).catch(() => {
      notification.error({ message: 'Fout tijdens het ophalen van de instellingen!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
    }).finally(() => {
      loading.value = false
    })
  }

  function getSetting<T> (settingName: string, defaultValue: T) {
    const lsValue = ls.get(settingName)
    if (lsValue !== undefined && lsValue !== '' && lsValue !== null) {
      return lsValue as T
    }
    const setting = settings.value.find((s) => s.setting_name === settingName)
    return setting ? setting.setting_value : defaultValue as T
  }

  function setSetting<T> (settingName: string, settingValue: T, forceUpdate = false) :T {
    const setting = settings.value.find((s) => s.setting_name === settingName)
    if (setting && !forceUpdate) {
      setting.setting_value = settingValue as never
      new SecureLS({ isCompression: false }).set(settingName, settingValue)
    } else {
      saveSetting(settingName, settingValue as never)
    }
    ls.set(settingName, settingValue)
    return settingValue
  }

  function saveSetting (key: string, value: never) : void {
    axios.post(process.env.VUE_APP_API_URL + '/users/constructionSiteApp/settings', {
      settingName: key,
      settingValue: value
    }).then((r) => {
      const newSetting = {
        id: r.data.id,
        setting_name: key,
        setting_value: value
      }
      settings.value.push(newSetting)
    }).catch((e) => {
      console.error(e)
    })
  }

  return {
    settings,
    loading,
    errors,
    loadSettings,
    getSetting,
    setSetting
  }
})
