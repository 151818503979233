import Model from './Model'

export default class IssueModel extends Model {
  id!: number
  appointment_at!: string

  resource () : string {
    return 'issues'
  }
}

export const IssueTypes = Object.freeze({
  SERVICE: {
    name: 'Servicemelding',
    value: 1
  },
  DELIVERY: {
    name: 'PVO',
    value: 4
  },
  SURVEY: {
    name: 'Keuring',
    value: 5
  },
  INSPECTION: {
    name: 'Voorschouw',
    value: 6
  },
  RENTAL: {
    name: 'Huurmelding',
    value: 7
  }
})

export const IssueStates = Object.freeze({
  Nieuw: 0,
  InBehandeling: 1,
  Opgelost: 2,
  Akkoord: 3,
  Gesloten: 4,
  NietAkkoord: 5,
  Heropend: 6,
  Gereed: 7,
  Afgewezen: 8,
  NietMeeEens: 9,
  InAfwachting: 10,
  Ingepland: 11,
  Doorgestuurd: 12,
  Geschil: 13,
  Beantwoord: 14
})
