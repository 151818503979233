import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import SurveyModel from '@/models/SurveyModel'
import TableSettings from '@/types/tableSettings'
import { notification } from 'ant-design-vue'
import { WrappedResponse } from 'vue-api-query'
import { useRouter } from 'vue-router'
import { authStore } from '@/stores/auth'
import { ClosedSurveyStates, OpenSurveyStates } from '@/helpers/surveyStates'
import { Survey } from '@/types/survey'
import axios from 'axios'

export const surveyStore = defineStore('survey', () => {
  const router = useRouter()
  const loading = ref({ list: false, item: false, saving: false })
  const surveys = ref<WrappedResponse<SurveyModel[]>|SurveyModel[]>({
    data: [],
    meta: {
      per_page: 25,
      total: 0
    }
  })
  const survey = ref<Survey|null>(null)

  const surveyClosed = computed(() => {
    return survey.value && ClosedSurveyStates.includes(survey.value.status)
  })

  const isSurveyOwner = computed(() => {
    return survey.value && survey.value.scheduled_user_id === authStore().user.id
  })

  const isExternalInspection = computed(() => {
    return survey.value && survey.value.survey_form && survey.value.survey_form.type === 9
  })

  function getSurveys (tableSettings:TableSettings) : void {
    loading.value.list = true
    let surveyModel = new SurveyModel()

    // Add filters if there are any filters set.
    if (tableSettings.activeFilters) {
      Object.keys(tableSettings.activeFilters).forEach(key => {
        if (tableSettings.activeFilters[key].length) {
          surveyModel = surveyModel.whereIn(key, tableSettings.activeFilters[key])
        }
      })
    }

    // Check if only archived surveys have to be shown.
    if (tableSettings.archived) {
      surveyModel = surveyModel.whereIn('status', tableSettings.activeFilters?.status || ClosedSurveyStates)
    } else {
      surveyModel = surveyModel.whereIn('status', tableSettings.activeFilters?.status || OpenSurveyStates)
    }

    // Add search if available.
    if (tableSettings.search) {
      surveyModel = surveyModel.where('search', tableSettings.search)
    }

    // Add orderBy if sort is set.
    if (tableSettings.sort && tableSettings.sort.order && tableSettings.sort.columnKey) {
      surveyModel = surveyModel.orderBy(tableSettings.sort.order === 'ascend' ? tableSettings.sort.columnKey : '-' + tableSettings.sort.columnKey)
    }

    if (tableSettings.pagination) {
      surveyModel = surveyModel.limit(tableSettings.pagination.pageSize).page(tableSettings.pagination.current)
    }

    surveyModel.get().then((r) => {
      surveys.value = r
    }).catch(() => {
      notification.error({ message: 'Fout tijdens het ophalen van de keuringen!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
    }).finally(() => {
      loading.value.list = false
    })
  }

  function getSurvey (id:string) : void {
    loading.value.item = true
    const surveyModel = new SurveyModel()

    surveyModel.find(id).then((r:any) => {
      survey.value = r.data
    }).catch((e:{ response: { status:number } }) => {
      if (e.response.status === 403) {
        notification.warning({
          message: 'U heeft niet de juiste rechten!',
          description: 'U heeft niet de juiste rechten om deze keuring te bekijken.',
          duration: 5
        })
      } else {
        notification.error({
          message: 'Fout tijdens het ophalen van deze keuring!',
          description: 'Er is iets mis gegaan. Probeer het later nog een keer.'
        })
      }
      router.push({ name: 'Surveys' })
    }).finally(() => {
      loading.value.item = false
    })
  }

  function updateSurvey (field:string, value:string|number|null) : void {
    surveyStore().survey[field] = value
    axios.patch(process.env.VUE_APP_API_URL + '/surveys/' + survey.value?.id, { [field]: value }).then((r) => {
      survey.value = r.data.data
    })
  }

  function completeSurvey (signature:File) : void {
    loading.value.saving = true
    const formData = new FormData()
    formData.append('files', signature)

    axios.post(process.env.VUE_APP_API_URL + '/surveys/' + survey.value?.id + '/complete', formData).then((r) => {
      survey.value = r.data.data
      loading.value.saving = false
      notification.success({
        message: 'Keuring opgeslagen.',
        description: 'De keuring is succesvol opgeslagen.',
        duration: 5
      })
    }).catch(() => {
      notification.error({
        message: 'Fout tijdens het opslaan van de keuring!',
        description: 'Er is iets mis gegaan. Probeer het later nog een keer.'
      })
    }).finally(() => {
      loading.value.saving = false
    })
  }

  function updateAnswer (id:number, answer:{ answer:string|null, note:string|null }) :void {
    axios.post(process.env.VUE_APP_API_URL + '/surveys/answers/' + id + '/update', answer).then((r) => {
      survey.value = r.data.data
    })
  }

  return { surveys, survey, surveyClosed, isSurveyOwner, isExternalInspection, loading, getSurveys, getSurvey, updateSurvey, completeSurvey, updateAnswer }
})
