import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { authStore } from '@/stores/auth'
import { notification } from 'ant-design-vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/O365LoginSucceeded',
    name: 'O365LoginSucceeded',
    component: () => import(/* webpackChunkName: "o365Login" */ '../views/O365LoginSucceeded.vue')
  },
  {
    path: '/wachtwoord-vergeten',
    name: 'ForgotPassword',
    component: () => import(/* webpackChunkName: "forgotPassword" */ '../views/ForgotPassword.vue')
  },
  {
    path: '/wachtwoord-wijzigen',
    name: 'ActivatePassword',
    component: () => import(/* webpackChunkName: "activatePassword" */ '../views/ActivatePassword.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import(/* webpackChunkName: "account" */ '../views/Account.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/medewerkers',
    name: 'ConstructionWorkers',
    component: () => import(/* webpackChunkName: "constructionWorkers" */ '../views/constructionWorker/ConstructionWorkers.vue'),
    meta: {
      requiresAuth: true,
      permissions: ['construction_site_portal.construction_workers.view']
    }
  },
  {
    path: '/medewerker/toevoegen',
    name: 'ConstructionWorkerCreate',
    component: () => import(/* webpackChunkName: "constructionWorkerCreate" */ '../views/constructionWorker/ConstructionWorkerCreate.vue'),
    meta: {
      requiresAuth: true,
      permissions: ['construction_site_portal.construction_workers.edit']
    }
  },
  {
    path: '/medewerker/:constructionWorkerId',
    name: 'ConstructionWorkerShow',
    component: () => import(/* webpackChunkName: "constructionWorkerShow" */ '../views/constructionWorker/ConstructionWorkerShow.vue'),
    meta: {
      requiresAuth: true,
      permissions: ['construction_site_portal.construction_workers.view']
    }
  },
  {
    path: '/medewerker/:constructionWorkerId/bewerken',
    name: 'ConstructionWorkerEdit',
    component: () => import(/* webpackChunkName: "constructionWorkerEdit" */ '../views/constructionWorker/ConstructionWorkerEdit.vue'),
    meta: {
      requiresAuth: true,
      permissions: ['construction_site_portal.construction_workers.edit']
    }
  },
  {
    path: '/project/:projectId/registraties',
    name: 'ConstructionSiteRegistrations',
    component: () => import(/* webpackChunkName: "constructionSiteRegistrations" */ '../views/project/ConstructionSiteRegistrations.vue'),
    meta: {
      requiresAuth: true,
      onlyInternal: true
    }
  },
  {
    path: '/project/:projectId/registratie',
    name: 'ConstructionSiteRegistrationCreate',
    component: () => import(/* webpackChunkName: "constructionSiteRegistrationCreate" */ '../views/project/ConstructionSiteRegistrationCreate.vue'),
    meta: {
      requiresAuth: true,
      onlyInternal: true
    }
  },
  {
    path: '/weekrapporten/project/:projectId',
    name: 'ProjectTimesheets',
    component: () => import(/* webpackChunkName: "projectTimesheets" */ '../views/timesheet/ProjectTimesheets.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/weekrapport/project/:projectId/dagrapport/:timesheetId',
    name: 'ProjectTimesheet',
    component: () => import(/* webpackChunkName: "projectTimesheet" */ '../views/timesheet/ProjectTimesheet.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/servicemeldingen',
    name: 'Issues',
    component: () => import(/* webpackChunkName: "issues" */ '../views/issue/Issues.vue'),
    meta: {
      requiresAuth: true,
      permissions: ['construction_site_portal.issues.view']
    }
  },
  {
    path: '/servicemelding/:issueId',
    name: 'IssueShow',
    component: () => import(/* webpackChunkName: "issueShow" */ '../views/issue/IssueShow.vue'),
    meta: {
      requiresAuth: true,
      permissions: ['construction_site_portal.issues.edit']
    }
  },
  {
    path: '/keuringen',
    name: 'Surveys',
    component: () => import(/* webpackChunkName: "surveys" */ '../views/survey/Surveys.vue'),
    meta: {
      requiresAuth: true,
      permissions: ['construction_site_portal.surveys.view', 'construction_site_portal.external_inspections.view']
    }
  },
  {
    path: '/keuring/:surveyId',
    name: 'SurveyShow',
    component: () => import(/* webpackChunkName: "surveyShow" */ '../views/survey/Survey.vue'),
    meta: {
      requiresAuth: true,
      permissions: ['construction_site_portal.surveys.edit', 'construction_site_portal.external_inspections.edit']
    }
  },
  {
    path: '/voorschouwen-opleveren',
    name: 'Deliveries',
    component: () => import(/* webpackChunkName: "deliveries" */ '../views/delivery/Deliveries.vue'),
    meta: {
      requiresAuth: true,
      permissions: ['construction_site_portal.deliveries.view']
    }
  },
  {
    path: '/voorschouw/:deliveryId',
    name: 'InspectionShow',
    component: () => import(/* webpackChunkName: "deliveryShow" */ '../views/delivery/Delivery.vue'),
    meta: {
      requiresAuth: true,
      permissions: ['construction_site_portal.deliveries.edit']
    }
  },
  {
    path: '/oplevering/:deliveryId',
    name: 'DeliveryShow',
    component: () => import(/* webpackChunkName: "deliveryShow" */ '../views/delivery/Delivery.vue'),
    meta: {
      requiresAuth: true,
      permissions: ['construction_site_portal.deliveries.edit']
    }
  },
  {
    path: '/uitvoerdersdagboek',
    name: 'Diaries',
    component: () => import(/* webpackChunkName: "diaries" */ '../views/diary/Diaries.vue'),
    meta: {
      requiresAuth: true,
      permissions: ['construction_site_portal.diaries.view']
    }
  },
  {
    path: '/uitvoerdersdagboek/:diaryId/bewerken',
    name: 'DiaryEdit',
    component: () => import(/* webpackChunkName: "diaryEdit" */ '../views/diary/DiaryEdit.vue'),
    meta: {
      requiresAuth: true,
      permissions: ['construction_site_portal.diaries.edit']
    }
  },
  {
    path: '/uitvoerdersdagboek/:diaryId',
    name: 'DiaryShow',
    component: () => import(/* webpackChunkName: "diaryShow" */ '../views/diary/DiaryShow.vue'),
    meta: {
      requiresAuth: true,
      permissions: ['construction_site_portal.diaries.view']
    }
  },
  {
    path: '/kam-formulieren',
    name: 'KAMForms',
    component: () => import(/* webpackChunkName: "kamForms" */ '../views/kam/KAMForms.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/kam/toevoegen',
    name: 'KAMFormCreate',
    component: () => import(/* webpackChunkName: "kamFormCreate" */ '../views/kam/KAMFormCreate.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/kam/F0009/:kamFormId',
    name: 'KAMFormF0009',
    component: () => import(/* webpackChunkName: "kamFormF0009" */ '../views/kam/forms/F0009.vue'),
    meta: {
      requiresAuth: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const auth = authStore()
  window.scrollTo(0, 0)

  if (to.meta.requiresAuth) {
    if (auth.loggedIn) {
      if (to.meta.onlyInternal && !auth.isInternal) {
        notification.warning({
          message: 'Toegang geweigerd!',
          description: 'U heeft niet de juiste rechten voor dit onderdeel.'
        })
        return
      }

      // Check permissions
      const requiredPermissions: string[] = (to.meta.permissions as string[]) || []
      if (requiredPermissions.length > 0) {
        const hasPermission = requiredPermissions.some(function (permission:string) {
          return auth.user.permissions.includes(permission)
        })

        if (!hasPermission) {
          notification.warning({
            message: 'Toegang geweigerd!',
            description: 'U heeft niet de juiste rechten voor dit onderdeel.'
          })
          return
        }
      }

      next()
      return
    }
    next('/')
    return
  }

  next()
})

export default router
